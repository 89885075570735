import React from 'react'

import Logo from '../../assets/unicesumar-logo.png'
import {Container} from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faBars  } from '@fortawesome/free-solid-svg-icons'

export const Header = () => {
  return (
    <Container>
        <a href='https://www.unicesumar.edu.br'>
          <img src={Logo} alt="logo"/>
        </a>
        <div className='buttons'>
          <FontAwesomeIcon icon={faSearch} color='#20659f' size='lg' />
          <FontAwesomeIcon icon={faUser} color='#20659f' size='lg' />
          <FontAwesomeIcon icon={faBars} color='#20659f' size='lg' />
        </div>
    </Container>
  )
}
