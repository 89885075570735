import { Header } from "./components/header";
import { Validation } from "./components/validation";

import { Title, Container } from './style'

export const App = () => {
  return (
    <>
      <Header/>
      <section>
        <Title>Carteirinha</Title>
      </section>
      <Container>
        <div className="alert-info">
            Para validar a carteirinha de estudante, digite no campo abaixo o código de validação impresso no verso da carteirinha.
        </div>
        <Validation />
      </Container>
    </>
  );
}

export default App;
