import styled from "styled-components";

export const Container = styled.section`
  padding: 60px 30px 40px;

  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
  }
`

export const Title = styled.h1`
  margin: 20px 30px;
  padding: 40px 0;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #20659f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-color: #c3c0b9;
  }
`