import styled from "styled-components";

export const Container = styled.div`
  
`
export const ValidationAction = styled.div`
    min-height: 20px;
    padding: 12px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;

    .label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }

    input {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }

    .validate-button {
      color: #fff;
      background-color: #5bc0de;
      border-color: #46b8da;
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
    }

    .validate-button:hover {
      background-color: #31b0d5;
      border-color: #269abc;
    }
`

export const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`

export const SuccessAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #3c763d;

  span {
    font-weight: 700;
    margin-top: 6px;
    text-align: center;
  }
`
export const ErrorAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #a94442;

  span {
    font-weight: 700;
    margin-top: 6px;
    text-align: center;
  }
`
export const UserInfoBox = styled.div`
  width: 100%;
  margin: 20px auto;
  padding: 5px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-height: 112px;
  display: flex;
  justify-content: space-between;

`