import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  height: 75px;
  background: #f3f3f3;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  z-index: 2000;
  top: 0%;
  margin: 0;
  position: sticky;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 3px;
    background-color: #ffa500;
  }

  img {
    height: 36px;
  }

  .buttons {
    font-size: 1.2rem;
  }
  .buttons > svg {
    padding: 5px;
  }
`