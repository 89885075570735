import React, { useCallback, useEffect, useState } from 'react'
import ReactLoading from "react-loading"

import { Container, ValidationAction, SuccessAlert, ErrorAlert, UserInfoBox, LoadingBox } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faBan } from '@fortawesome/free-solid-svg-icons'

import Photo from '../../assets/photo.png'


export const Validation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const codigoParam = urlParams.get('codigo')
  const [show, setShow] = useState(false)
  const [error, setError] = useState(false)
  const [errorCode, seteErorCode] = useState('')
  const [codigo, setCodigo] = useState(codigoParam || '')
  const [loading, setLoading] = useState(false)

  const codUser = '1697066429479-43'

  const data = [
    {name: 'RA', value: '23439000-5'},
    {name: 'Aluno', value: 'KAUÊ EMANUEL MALHEIROS BRASIL'},
    {name: 'Curso', value: 'LICENCIATURA EM EDUCAÇÃO FÍSICA'},
    {name: 'Validade', value: '09/10/2024'},
  ]

  const getInfo = useCallback(
    () => {
      if (!codigo) {
        setShow(false)
        setError(false)
        return
      }
      setLoading(true)
      setTimeout(() => {
        if (codUser === `${codigo}`) {
          setShow(true)
          setError(false)
        } else {
          seteErorCode(codigo)
          setShow(false)
          setError(true)
        }
        setLoading(false)
      }, 3000);
    },
    [codigo],
  )

  useEffect(() => {
    if(codigo) {
      getInfo()
    }
  }, [])
  
  return (
    <Container>
      <ValidationAction>
        <div className='label'>Código de Validação:</div>
        <input value={codigo} onChange={e => setCodigo(e.target.value)} type="text" />
        <button className='validate-button' onClick={getInfo}>Validar</button>
      </ValidationAction>
      {
        loading &&
        <LoadingBox>
          <ReactLoading
            type={"bars"}
            color={"#e3e3e3"}
            height={60}
            width={60}
          />
        </LoadingBox>
      }
      {
        !loading && show && 
        <>
          <SuccessAlert>
            <FontAwesomeIcon icon={faCircleCheck} color='#3c763d' size='4x' />
            <span>CARTEIRINHA VÁLIDA</span>
          </SuccessAlert>
          <UserInfoBox>
            <div>
            {
              data.map(({name, value}) => (
                <div key={name}><strong>{name}: </strong> {value}</div>
              ))
            }
            </div>
            <div className='photo'>
              <img src={Photo} alt="user" height={120} />
            </div>
          </UserInfoBox>
        </>
      }
      {
        !loading && error && 
        <>
          <ErrorAlert>
            <FontAwesomeIcon icon={faBan} color='#a94442' size='4x' />
            <span>CARTEIRINHA INVÁLIDA: {errorCode}</span>
          </ErrorAlert>

        </>
      }
    </Container>
  )
}
